import ConversionRule from "store/models/ConversionRule";
import Actions from "modules/actions/conversion-rules-action";

const actions = new Actions();

export default {
  name: "ConversionRules",
  data() {
    return {
      filter: "",
      isDataLoading: false,
      initialDataLoading: false,
      conversionRules: [],
      orderBy: {
        internalId: "orderByInternalId",
        name: "orderByName",
        description: "orderByDescription",
        validityStartDate: "orderByValidityStartDate",
        validityEndDate: "orderByValidityEndDate",
        status: "orderByValidityStatus",
        created: "orderByCreated",
      },
      auditTrails: {
        id: null,
        isVisible: false,
      },
      columns: [
        {
          name: "internalId",
          align: "left",
          text: "Rule Code",
          field: "internalId",
          sortable: true,
        },
        {
          name: "name",
          align: "left",
          text: "Name Of Rule",
          field: "name",
          sortable: true,
        },
        {
          name: "description",
          align: "left",
          text: "Description of Rule",
          field: "description",
          sortable: true,
        },
        {
          align: "left",
          name: "validityStartDate",
          text: "Effective Date",
          field: "validityStartDate",
          sortable: true,
          sortableByDate: true,
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
        },
        {
          name: "validityEndDate",
          align: "left",
          text: "Termination Date",
          field: "validityEndDate",
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
          sortable: true,
          sortableByDate: true,
        },
        {
          name: "created",
          align: "left",
          text: "Creation Date",
          field: "created",
          sortable: true,
          sortableByDate: true,
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
        },
        {
          name: "status",
          align: "center",
          text: "Status",
          field: "status",
          sortable: true,
          isStatusColumn: true,
        },
        {
          name: "action",
          text: "Action",
          field: "action",
          align: "center",
        },
      ],
      searchFilter: {
        columns: 3,
        model: ConversionRule,
        fields: [
          {
            label: "Rule Code",
            name: "internalId",
            type: "text",
            maxlength: 10,
          },
          {
            label: "Name of Rule",
            name: "name",
            type: "text",
            maxlength: 30,
          },
          {
            label: "Description of Rule",
            name: "description",
            type: "text",
            maxlength: 50,
          },
          {
            label: "Effective Date",
            name: "validityStartDate",
            type: "date",
            format: "MM/DD/YYYY",
          },
          {
            label: "Termination Date",
            name: "validityEndDate",
            type: "date",
            format: "MM/DD/YYYY",
          },
          {
            label: "Creation Date",
            name: "created",
            type: "date",
          },
        ],
      },
      limitData: [
        "name",
        "internalId",
        "description",
        "validityStartDate",
        "validityEndDate",
        "created",
        "isActive",
        "totalCount",
      ],
      totalCount: 0,

      changeStatusForm: {
        id: null,
        isVisible: false,
        isActive: false,
        isDisabled: false,
      },
    };
  },
  computed: {
    filteredConversionRules() {
      try {
        const data = this.conversionRules.map((item) => ({
          id: item.id,
          internalId: item.internalId,
          name: item.name,
          description: item.description,
          validityStartDate: item.validityStartDate
            ? this.$options.filters.getTimeDate(
                item.validityStartDate,
                "MM/DD/YYYY"
              ).zone
            : null,
          validityEndDate: item.validityEndDate
            ? this.$options.filters.getTimeDate(
                item.validityEndDate,
                "MM/DD/YYYY"
              ).zone
            : null,
          created: item.created
            ? this.$options.filters.getTimeDate(item.created, "MM/DD/YYYY").zone
            : null,
          isActive: item.isActive,
          status: item.isActive ? "Active" : "Deactivated",
        }))
        return data;
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while fetching data.",
          type: "danger",
        });
      }
    },
  },

  methods: {
    loading(bool) {
      this.isDataLoading = bool;
    },
    async searchResults(result) {
      if (result.length) {
        this.totalCount = result[0].totalCount;
      } else {
        this.totalCount = 0;
      }
      this.conversionRules = result;
    },
    addNewRule() {
      this.$router.push("/system-maintenance/conversion-rules/add");
    },
    editRule(id) {
      this.$router.push(`/system-maintenance/conversion-rules/edit/${id}`);
    },
    async fetch() {
      try {
        this.resetDataTableOptions();
        await this.$refs.searchFilter.filterSearchModel(10, null);
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while fetching data.",
          type: "danger",
        });
      }
    },
    toggleAuditTrail(isVisible, id = null) {
      this.auditTrails = {
        id,
        isVisible,
      };
    },

    async toggleChangeStatus(id, isActive) {
      this.handleChangeStatusVisibility(true, id, isActive);
    },

    handleChangeStatusVisibility(value, id = null, isActive = false) {
      const { isVisible } = this.changeStatusForm;
      if (isVisible !== value) {
        this.changeStatusForm = {
          ...this.changeStatusForm,
          isVisible: value,
          id,
          isActive,
        };
      }
    },

    async onSubmitChangeStatus() {
      const { id, isActive, isDisabled } = this.changeStatusForm;

      if (!isDisabled) {
        this.changeStatusForm.isDisabled = true;
        try {
          const variables = {
            conversionID: {
              type: "UUID!",
              value: id,
              unincludeToFields: true,
            },
            limitData: ["success", "errors"],
            shouldNotReturnId: true,
          };
          const { success, errors } =
            await actions.updateVBSConversionRuleStatus(variables, isActive);

          if (!success || errors?.length > 0) {
            const errorMesssage =
              errors.length > 0
                ? errors[0]
                : "Problem has occurred while updating Conversion Rule status.";

            this.showNotifyMessage({
              message: errorMesssage,
              type: "danger",
            });
          }

          this.changeStatusForm = {
            id: null,
            isVisible: false,
            isActive: false,
            isDisabled: false,
          };

          await this.fetch();

          this.showNotifyMessage({
            message: `Conversion Rule ${
              isActive ? "deactivated" : "activated"
            }.`,
            type: "success",
          });
        } catch (error) {
          this.showNotifyMessage({
            message: "Unable to update status",
            type: "danger",
          });
          this.changeStatusForm.isDisabled = false;
        }
      }
    },
    resetDataTableOptions() {
      this.$refs.dataTable.resetDataTableOptions();
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.initialDataLoading = true;
      await this.fetch();
      this.initialDataLoading = false;
    });
  },
};
