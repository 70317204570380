var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-searchfilter',{ref:"searchFilter",attrs:{"options":_vm.searchFilter.fields,"modelInstance":_vm.searchFilter.model,"columns":_vm.searchFilter.columns,"limitData":_vm.limitData,"limit":10,"isLoading":_vm.isDataLoading,"relativeUrl":"/conversion-rules/get-all","queryName":"vbsConversionRules","hasArchiveText":"Include Deactivated","hasArchive":"","hideDeleted":"","allowFutureDates":""},on:{"searchResults":_vm.searchResults,"loading":_vm.loading,"resetDataTableOptions":_vm.resetDataTableOptions}})],1)],1),_c('v-row',[_c('v-col',[_c('v-custom-datatable',{ref:"dataTable",staticClass:"data-table",attrs:{"addLabel":"Add New Rule","orderBy":_vm.orderBy,"data":_vm.filteredConversionRules,"columns":_vm.columns,"row-key":"id","filter":_vm.filter,"isLoading":_vm.isDataLoading,"initialLoading":_vm.initialDataLoading,"noDataLabel":"No Conversion Rule found","flatx":"","totalCount":_vm.totalCount,"searchFilter":_vm.$refs.searchFilter,"bordered":"","withAdd":"","hideDelete":true,"hideView":true,"hasAuditTrail":"","editLabel":"View/Edit","hasChangeStatus":"","accessControl":{
          authUserHasAddAccessControl: true,
          authUserHasEditAccessControl: true,
          authUserHasDeleteAccessControl: true,
        }},on:{"onAdd":_vm.addNewRule,"onEdit":_vm.editRule,"onChangeStatus":_vm.toggleChangeStatus,"onToggleAudit":function (id) { return _vm.toggleAuditTrail(true, id); }}})],1)],1),_c('v-change-status',{attrs:{"visible":_vm.changeStatusForm.isVisible,"id":_vm.changeStatusForm.id,"isActive":_vm.changeStatusForm.isActive,"isDisabled":_vm.changeStatusForm.isDisabled,"header":"Change Status","message":"You are about to change the status of this rule.","warning":"Changing the status will update the Termination Date to today's date and the deactivation will only take effect after the termination date.","buttonTexts":{
      cancel: 'Cancel',
      activate: 'Activate',
      deactivate: 'Deactivate',
    }},on:{"close":function($event){return _vm.handleChangeStatusVisibility(false)},"submit":_vm.onSubmitChangeStatus}}),(_vm.auditTrails.isVisible && _vm.auditTrails.id)?_c('v-audit-trail',{attrs:{"module":"CONVERSION_RULE","recordId":_vm.auditTrails.id,"visibility":_vm.auditTrails.isVisible},on:{"onClose":_vm.toggleAuditTrail}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }